body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", "Ubuntu", "Droid Sans", "Helvetica Neue", "Arial",
    -apple-system, BlinkMacSystemFont,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

div#root {
  min-height: 100vh;
}

/* @media (max-width:640px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio:2) {
  body {-webkit-text-size-adjust: 70%;}
} */

/* @media (max-width:640px) and (orientation: landscape) {
  body {-webkit-text-size-adjust: 100%;}
}

@media (max-width:640px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio:2) {
  body {-webkit-text-size-adjust: 70%;}
} */
